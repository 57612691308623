import React from 'react'
import Flex from './Flex'

export const GRID_DEFAULT_SPACING = 3

export default function Grid(props) {
  return (
    <Flex
      flexWrap="wrap"
      flexDirection="row"
      mx={GRID_DEFAULT_SPACING * -1}
      {...props}
    />
  )
}
