import React from 'react'
import LinkExternal from './LinkExternal'

const LinkExternalSafe = (props) => (
  <LinkExternal
    rel="noopener noreferrer"
    {...props}
  />
)

export default LinkExternalSafe
