import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { List, ListItem, LinkExternalSafe } from '../../ui'

const PartnerLogos = () => (
  <List
    mx={-2}
    alignItems="center"
    flexDirection="row"
  >
    <ListItem px={2}>
      <LinkExternalSafe
        title="Hortobágyi Nemzeti Park Igazgatóság"
        href="https://www.hnp.hu/hu"
      >
        <StaticImage
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-hnp.png"
          alt="Hortobágyi Nemzeti Park Igazgatóság"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem px={2}>
      <LinkExternalSafe
        title="KÖTIVIZIG"
        href="http://www.kotivizig.hu"
      >
        <StaticImage
          alt="KÖTIVIZIG"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-kotivizig.png"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem px={2}>
      <LinkExternalSafe
        title="Nyaralóhajózás"
        href="https://www.nyaralohajozas.hu"
      >
        <StaticImage
          alt="Nyaralóhajózás"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-nyaralohajozas.png"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem px={2}>
      <LinkExternalSafe
        title="Tisza-tavi Ökocentrum"
        href="http://www.tiszataviokocentrum.hu/"
      >
        <StaticImage
          alt="Tisza-tavi Ökocentrum"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-okocentrum.png"
        />
      </LinkExternalSafe>
    </ListItem>
  </List>
)

export default PartnerLogos
