import { useStaticQuery, graphql } from 'gatsby'

// TODO this should come from yoast seo graphql data only
export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          messengerUrl
          youtubeEmbedUrl
        }
      }
    }
  `)

  return data.site.siteMetadata
}
