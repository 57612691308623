import React from 'react'
import styled from '@emotion/styled'
import Flex from './Flex'

const StyledFlex = styled(Flex)`
  list-style-type: none;
`

export default function List(props) {
  return (
    <StyledFlex
      p={0}
      as="ul"
      flexDirection="column"
      {...props}
    />
  )
}
