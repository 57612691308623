import React from 'react'
import Box from './Box'

const Placeholder = (props) => (
  <Box
    width={1}
    height={100}
    variant="placeholder"
    {...props}
  />
)

export default Placeholder
