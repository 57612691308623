import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { List, ListItem, LinkExternalSafe } from '../../ui'

const SupporteeLogos = () => (
  <List
    alignItems="center"
    flexDirection="row"
  >
    <ListItem px={1}>
      <LinkExternalSafe
        title="Pet Kupa"
        href="https://petkupa.hu/hu_HU"
      >
        <StaticImage
          alt="Pet Kupa"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-pet-kupa-inverse.png"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem px={1}>
      <LinkExternalSafe
        title="Unicef"
        href="https://unicef.hu"
      >
        <StaticImage
          alt="Unicef"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-unicef-inverse.png"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem px={1}>
      <LinkExternalSafe
        href="https://wwf.hu"
      >
        <StaticImage
          alt="WWF"
          width={60}
          layout="fixed"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-wwf-inverse.png"
        />
      </LinkExternalSafe>
    </ListItem>
  </List>
)

export default SupporteeLogos
