import React, {
  useState, useCallback, useEffect, createContext,
} from 'react'
import throttle from 'lodash.throttle'
import { useManualQuery } from 'graphql-hooks'
import { GLOBAL_SEARCH_QUERY } from './queries'
import client from '../../graphqlClient'

const initialState = {
  searchValue: null,
}

export const SearchPreviewContext = createContext(initialState)

const throttleConfig = {
  leading: false,
  trailing: true,
}

const SearchPreviewContextProvider = ({ children, initialSearchValue = '' }) => {
  const [submitted, setSubmitted] = useState(false)
  const [searchValue, setSearchValue] = useState(initialSearchValue)
  const [requestSearch, { loading, error, data }] = useManualQuery(GLOBAL_SEARCH_QUERY, { client })

  const throttledRequestSearch = useCallback(throttle((newValue) => {
    requestSearch({
      variables: {
        first: 11,
        search: newValue.trim(),
        mediaSize: 'SMALL_POST',
      },
    })
  }, 1000, throttleConfig), [])

  useEffect(() => {
    if (searchValue) {
      setSubmitted(true)
      throttledRequestSearch(searchValue)
    }
  }, [searchValue])

  return (
    <SearchPreviewContext.Provider
      value={{
        searchValue,
        setSearchValue,
        error,
        loading,
        submitted,
        searchResults: data?.results?.nodes,
      }}
    >
      {children}
    </SearchPreviewContext.Provider>
  )
}

export default SearchPreviewContextProvider
