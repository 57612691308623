import React from 'react'
import { Global, css } from '@emotion/core'
import theme from './theme'

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
          *,
          *::after,
          *::before {
            box-sizing: inherit;
          }

          body {
            margin: 0;
            box-sizing: border-box;
            color: ${theme.colors.text};
            font-family: ${theme.fonts.body};
            background-color: ${theme.colors.background.body};
          }
        `}
    />
  )
}
