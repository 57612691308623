import React, { useState, createContext } from 'react'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'

const initialState = {
  selectedYear: null,
  selectedMonth: null,
}

export const CalendarContext = createContext(initialState)

const todaysDate = new Date()
const defaultYear = getYear(todaysDate)
const defaultMonth = getMonth(todaysDate)

const CalendarContextProvider = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState(defaultYear)
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth)

  const minMonth = defaultMonth <= 2 ? 11 : defaultMonth - 2
  const maxMonth = defaultMonth >= 6 ? 1 : defaultMonth + 3

  const selectPrevMonth = React.useCallback(() => {
    if (selectedMonth <= 0) {
      setSelectedMonth(11)
      setSelectedYear((currYear) => currYear + -1)
    } else {
      setSelectedMonth((currMonth) => currMonth - 1)
    }
  }, [selectedMonth])

  const selectNextMonth = React.useCallback(() => {
    if (selectedMonth >= 11) {
      setSelectedMonth(0)
      setSelectedYear((currYear) => currYear + 1)
    } else {
      setSelectedMonth((currMonth) => currMonth + 1)
    }
  }, [selectedMonth])

  return (
    <CalendarContext.Provider
      value={{
        minMonth,
        maxMonth,
        selectedYear,
        selectedMonth,
        setSelectedYear,
        setSelectedMonth,
        selectPrevMonth,
        selectNextMonth,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

export default CalendarContextProvider
