import React from 'react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

const LinkExternal = (props) => (
  // eslint-disable-next-line
  <a
    target="_blank"
    {...props}
  />
)

export default styled(LinkExternal)(
  variant({
    scale: 'link',
  }),
)
