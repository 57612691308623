import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { GlobalStyles, theme } from './components/ui'

const AppWrapper = ({ element }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  </>
)

export default AppWrapper
