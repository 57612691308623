import React from 'react'
import Box from './Box'

export default function ListItem(props) {
  return (
    <Box
      p={0}
      as="li"
      {...props}
    />
  )
}
