import React from 'react'
import styled from '@emotion/styled'
import { Box, Heading } from '../../ui'

const StyledBox = styled(Box)`
  margin-top: -3px;
  border-bottom: 3px solid #3A3A3A;
`

const StyledHeading = styled(Heading)`
  display: inline-block;
  padding-bottom: 6px;
  margin-bottom: -3px;
  text-transform: uppercase;
  border-bottom: 3px solid ${(props) => props.theme.colors.primary[50]};
`

const UnderlinedHeading = ({ children, as = 'h3', ...props }) => (
  <StyledBox {...props}>
    <StyledHeading
      as={as}
      fontSize={3}
    >
      {children}
    </StyledHeading>
  </StyledBox>
)

export default UnderlinedHeading
