import { useStaticQuery, graphql } from 'gatsby'

export default function useSiteTitle() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }

      wp {
        allSettings {
          generalSettingsTitle
        }
      }
    }
  `)

  return data.wp.allSettings.generalSettingsTitle || data.site.siteMetadata.title
}
