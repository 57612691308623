import styled from '@emotion/styled'
import { Box } from 'rebass'
import {
  zIndex,
  top,
  right,
  bottom,
  left,
  position,
  boxShadow,
  borderRadius,
} from 'styled-system'

export default styled(Box)(
  zIndex,
  top,
  right,
  bottom,
  left,
  position,
  boxShadow,
  borderRadius,
)
