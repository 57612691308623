import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Box, List, ListItem, Link,
} from '../../ui'

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        menus(where: {location: FOOTER_NAV}) {
          nodes {
            menuItems {
              nodes {
                path
                label
              }
            }
          }
        }
      }
    }
  `)

  const items = data.wp.menus.nodes[0].menuItems.nodes

  return (
    <Box
      as="nav"
      role="navigation"
    >
      <List my={[-3, -1]}>
        {items.map(({ label, path }, i) => (
          <ListItem
            py={[3, 1]}
            key={`${path}-${String(i)}`}
          >
            <Link
              to={path}
              variant="secondary"
            >
              {label}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default FooterNav
