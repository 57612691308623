import React from 'react'

const Parsed = ({ children = '', component: Component = 'div', ...props }) => (
  <Component
    {...props}
    dangerouslySetInnerHTML={{
      __html: children,
    }}
  />
)

export default Parsed
