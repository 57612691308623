import fetch from 'isomorphic-fetch'
import { GraphQLClient } from 'graphql-hooks'
import memCache from 'graphql-hooks-memcache'
import { API_URL } from './config'

export default new GraphQLClient({
  fetch,
  url: API_URL,
  ssrMode: false,
  cache: memCache(),
})
