import React from 'react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'
import { Link as RebassLink } from 'rebass'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
  to, partiallyActive, activeClassName, ...props
}) => (
  <RebassLink
    {...props}
    as={(linkProps) => (
      <GatsbyLink
        {...linkProps}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={to === '/' ? undefined : partiallyActive}
      />
    )}
  />
)

export default styled(Link)(
  variant({
    scale: 'link',
  }),
)
