import React from 'react'
import Box from './Box'

export default function ContainerFixed(props) {
  return (
    <Box
      px={3}
      mx="auto"
      maxWidth={1140}
      {...props}
    />
  )
}
