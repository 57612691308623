import React from 'react'
import { Helmet } from 'react-helmet'
import { getImage, getSrc } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import useSiteTitle from '../../hooks/useSiteTitle'
import useSiteDescription from '../../hooks/useSiteDescription'

const MetaTagsDefault = () => {
  const title = useSiteTitle()
  const description = useSiteDescription()
  const { fbShareImage } = useStaticQuery(graphql`
    query SeoQuery {
      fbShareImage: file(relativePath: {eq: "images/identity/fb_share.png"}) {
        childImageSharp {
          gatsbyImageData(
            quality: 30
            placeholder: NONE
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const shareImageUrl = getSrc(
    getImage(fbShareImage),
  )

  return (
    <Helmet>
      <html lang="hu" />
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />

      <meta
        property="og:locale"
        content="hu_HU"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:site_name"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:image"
        content={`${process.env.SITE_BASE_URL}${shareImageUrl}`}
      />
      <meta
        property="og:image:secure_url"
        content={`${process.env.SITE_BASE_URL}${shareImageUrl}`}
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />

      <link
        rel="preconnect"
        href="//www.google-analytics.com"
      />
      <link
        rel="preconnect"
        href={process.env.WP_BASE_URL}
      />
    </Helmet>
  )
}

export default MetaTagsDefault
