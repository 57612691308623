import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PrimaryNav from './PrimaryNav'

const NavLayoutResponsive = () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        menus(where: {
          location: MAIN_NAV
        }) {
          nodes {
            menuItems {
              nodes {
                path
                label
              }
            }
          }
        }
      }
    }
  `)

  return (
    <nav>
      <PrimaryNav
        pages={wp.menus.nodes[0].menuItems.nodes}
      />
    </nav>
  )
}

export default NavLayoutResponsive
