import { useStaticQuery, graphql } from 'gatsby'

export default function useSocialUrls() {
  const data = useStaticQuery(graphql`
    {
      wp {
        seo {
          social {
            facebook {
              url
            }
            instagram {
              url
            }
            youTube {
              url
            }
            linkedIn {
              url
            }
          }
        }
      }
    }
  `)

  return data.wp.seo.social
}
