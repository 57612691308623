// eslint-disable-next-line
export const GLOBAL_SEARCH_QUERY = `
  query GlobalSearchQuery(
    $first: Int!
    $after: String
    $search: String
    $mediaSize: MediaItemSizeEnum
  ) {
    results: contentNodes(
      first: $first
      after: $after
      where: {
        search: $search
        status: PUBLISH
        language: DEFAULT
        contentTypes: [POST, EVENT, LOCATION]
      }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        uri
        type: __typename
        ... on Post {
          title
          excerpt
          meta: formattedDate
          featuredImage {
            node {
              src: sourceUrl(size: $mediaSize)
              srcSet: sourceUrl(size: $mediaSize)
              mediaDetails {
                width
                height
              }
            }
          }
        }
        ... on Event {
          title
          excerpt
          startDate
          startTime
          endDate
          endTime
          isAllDay: allDay
          location {
            title: locationTitle
          }
          featuredImage {
            node {
              src: sourceUrl(size: $mediaSize)
              srcSet: sourceUrl(size: $mediaSize)
              mediaDetails {
                width
                height
              }
            }
          }
        }
        ... on Location {
          title
          town
          address
          excerpt
          featuredImage {
            node {
              src: sourceUrl(size: $mediaSize)
              srcSet: sourceUrl(size: $mediaSize)
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
