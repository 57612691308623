import React from 'react'
import {
  List, ListItem, Link, LinkExternalSafe,
} from '../../ui'
import useSiteTitle from '../../../hooks/useSiteTitle'
import useSiteMetadata from '../../../hooks/useSiteMetadata'
import useSocialUrls from '../../../hooks/useSocialUrls'
import instagramIcon from '../../../data/images/icons/icon-instagram-light.svg'
import youtubeIcon from '../../../data/images/icons/icon-youtube-light.svg'
import facebookIcon from '../../../data/images/icons/icon-facebook-light.svg'
import messengerIcon from '../../../data/images/icons/icon-messenger-light.svg'
import linkedinIcon from '../../../data/images/icons/icon-linkedin-light.svg'
import podcastIcon from '../../../data/images/icons/icon-podcast-light.svg'

const SocialLinks = () => {
  const title = useSiteTitle()
  const { messengerUrl } = useSiteMetadata()
  const {
    facebook, youTube, instagram, linkedIn,
  } = useSocialUrls()

  return (
    <>
      <List
        mx={-1}
        flexDirection="row"
      >
        <ListItem px={1}>
          <LinkExternalSafe
            href={instagram.url}
            title={`${title} az Instagramon`}
          >
            <img
              alt=""
              src={instagramIcon}
            />
          </LinkExternalSafe>
        </ListItem>
        <ListItem px={1}>
          <LinkExternalSafe
            href={youTube.url}
            title={`${title} a Youtube-on`}
          >
            <img
              alt=""
              src={youtubeIcon}
            />
          </LinkExternalSafe>
        </ListItem>
        <ListItem px={1}>
          <LinkExternalSafe
            href={facebook.url}
            title={`${title} a Facebookon`}
          >
            <img
              alt=""
              src={facebookIcon}
            />
          </LinkExternalSafe>
        </ListItem>
        <ListItem px={1}>
          <LinkExternalSafe
            href={messengerUrl}
            title={`${title} a Messengeren`}
          >
            <img
              alt=""
              src={messengerIcon}
            />
          </LinkExternalSafe>
        </ListItem>
        <ListItem px={1}>
          <LinkExternalSafe
            href={linkedIn.url}
            title={`${title} a Linkedinen`}
          >
            <img
              alt=""
              src={linkedinIcon}
            />
          </LinkExternalSafe>
        </ListItem>
      </List>
      <List
        mx={-1}
        mt={2}
        flexDirection="row"
      >
        <ListItem px={1}>
          <Link
            to="/podcast"
            title="Podcast"
          >
            <img
              alt=""
              src={podcastIcon}
            />
          </Link>
        </ListItem>
      </List>
    </>
  )
}

export default SocialLinks
