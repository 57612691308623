import React from 'react'

const EventDuration = ({
  startDate, endDate, startTime, endTime, isAllDay,
}) => {
  const isMultiDay = startDate !== endDate
  const dateNode = isMultiDay ? `${startDate} - ${endDate}` : startDate
  const timeNode = isAllDay ? null : ` ${startTime} - ${endTime}`

  return (
    <>
      {dateNode}
      {timeNode}
      {isAllDay && ' Egész napos'}
    </>
  )
}

export default EventDuration
