import React from 'react'
import Box from './Box'
import { GRID_DEFAULT_SPACING } from './Grid'

export default function GridItem(props) {
  return (
    <Box
      px={GRID_DEFAULT_SPACING}
      {...props}
    />
  )
}
