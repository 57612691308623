import styled from '@emotion/styled'
import { Box } from 'rebass'

export default styled(Box)`
  min-width: 270px;
  background-color: #fff;
  transition: opacity .2s ease-in;
  box-shadow: ${(props) => props.theme.shadows.large};
  border-radius: ${(props) => props.theme.radii.default}px;
`
