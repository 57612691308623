import React from 'react'
import styled from '@emotion/styled'
import { Box, Placeholder } from '../ui'
import { Lazy } from '../content'

const StyledImage = styled('img')`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  min-width: initial;
  max-width: initial;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`

const PostThumbnailRemote = ({
  src, srcSet, height = 170, alt = '', ...props
}) => (
  <Box
    height={height}
    overflow="hidden"
    position="relative"
    variant="placeholder"
    {...props}
    minWidth="100%"
    maxWidth="100%"
  >
    {
      src
        ? (
          <>
            <Lazy fallback={<Placeholder height={height} />}>
              <picture>
                <StyledImage
                  src={src}
                  alt={alt}
                />
              </picture>
            </Lazy>
            <noscript>
              <picture>
                <StyledImage
                  src={src}
                  alt={alt}
                />
              </picture>
            </noscript>
          </>
        ) : null
    }
  </Box>
)

export default PostThumbnailRemote
