import { useStaticQuery, graphql } from 'gatsby'

export default function useSiteDescription() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
        }
      }

      wp {
        allSettings {
          generalSettingsDescription
        }
      }
    }
  `)

  return data.wp.allSettings.generalSettingsDescription || data.site.siteMetadata.description
}
