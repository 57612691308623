import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { List, ListItem, LinkExternalSafe } from '../../ui'

const CompanyLinks = () => (
  <List
    flexDirection="column"
  >
    <ListItem>
      <LinkExternalSafe
        title="Datscience"
        href="https://datscience.hu"
      >
        <StaticImage
          width={190}
          layout="fixed"
          alt="Datscience"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-datscience.png"
        />
      </LinkExternalSafe>
    </ListItem>
    <ListItem>
      <LinkExternalSafe
        title="Podcasterz"
        href="https://www.podcasterz.hu/"
      >
        <StaticImage
          width={90}
          layout="fixed"
          alt="Datscience"
          placeholder="none"
          src="../../../data/images/partnerLogos/partner-logo-podcasterz.png"
        />
      </LinkExternalSafe>
    </ListItem>
  </List>
)

export default CompanyLinks
