import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import useSiteTitle from '../../../hooks/useSiteTitle'

const ValueProposition = () => {
  const title = useSiteTitle()

  return (
    <StaticImage
      alt={title}
      loading="eager"
      width={60}
      layout="fixed"
      placeholder="none"
      src="../../../data/images/identity/value-proposition.png"
    />
  )
}

export default ValueProposition
