import levenSort from 'leven-sort'

// eslint-disable-next-line
export const orderByRelevance = (arr = [], keyword) => levenSort(
  arr,
  keyword,
  ['title', 'uri'],
  keyword,
  'excerpt',
)
