import React, { Suspense } from 'react'
import { useInView } from 'react-intersection-observer'

async function loadPolyfills() {
  if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

loadPolyfills()

const Lazy = ({ children, fallback, options = {} }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    ...options,
  })

  return (
    <div ref={ref}>
      {
        inView && (
          <Suspense fallback={fallback}>
            {children}
          </Suspense>
        )
      }
    </div>
  )
}

export default Lazy
